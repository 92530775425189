import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import cn from 'mxcn';
import { LucideIcon } from 'lucide-react';
import Form, { FormProps } from './Form';
import SubmitButton from './SubmitButton';

const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 dark:focus-visible:ring-gray-300',
  {
    variants: {
      variant: {
        default:
          'bg-contrast-0 border border-contrast-100 text-contrast-800 shadow hover:border-contrast-400 hover:shadow-md active:shadow-none',
        primary:
          'bg-brand-700 border border-brand-800 dark:border-brand-600 text-brand-50 shadow hover:bg-brand-600 hover:border-brand-500 hover:shadow-md',
        destructive:
          'bg-red-500 text-contrast-100 shadow-sm hover:bg-red-500/90 dark:text-red-50 dark:bg-red-700 dark:hover:bg-red-600',
        outline:
          'border border-contrast-200 text-inherit bg-transparent shadow-sm hover:bg-contrast-100 hover:text-contrast-900',
        secondary:
          'bg-contrast-50 border border-contrast-100 text-contrast-900 shadow-sm hover:shadow-md hover:bg-contrast-100 hover:border-contrast-200',
        ghost: 'hover:bg-contrast-100 hover:text-contrast-900',
        link: 'text-contrast-900 underline-offset-4 hover:underline',
        headless: '',
      },
      size: {
        default: 'h-9 px-4 py-2',
        xs: 'h-6 rounded-md px-1.5 text-xs',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-12 rounded-md px-8 text-md',
        xl: 'h-14 rounded-lg px-8 text-lg',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  action?: FormProps<any>['action'];
  args?: Record<string, string>;
  asChild?: boolean;
  icon?: LucideIcon;
}

// eslint-disable-next-line react/display-name
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      action,
      args,
      children,
      className,
      variant,
      size,
      asChild = false,
      icon: Icon,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';

    if (action) {
      return (
        <Form action={action} className="contents">
          {args &&
            Object.entries(args).map(([name, value]) => (
              <input key={name} type="hidden" name={name} value={value} />
            ))}
          <SubmitButton
            variant={variant}
            size={size}
            className={className}
            {...props}
          >
            {children}
          </SubmitButton>
        </Form>
      );
    }

    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        draggable="false"
        {...props}
      >
        {children}
        {Icon && <Icon className="ml-[0.5em] w-[1.3em] h-[1.3em]" />}
      </Comp>
    );
  }
);

export { Button, buttonVariants };
