import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import config from '@/config';
import ms from 'ms';
import { useInterval } from 'usehooks-ts';
import { JobState } from 'bullmq';
import { getJobProgresses } from '../jobs';
import { uniq } from 'lodash';

const JobProgressContext = createContext<{
  jobsToMonitor: string[];
  jobProgresses: Record<string, any>;
  monitorJob(queueName: keyof typeof config.jobs.queues, jobId: string): void;
  stopMonitoringJob(
    queueName: keyof typeof config.jobs.queues,
    jobId: string
  ): void;
} | null>(null);

export function JobProgressProvider(props: { children: ReactNode }) {
  const [jobsToMonitor, setJobsToMonitor] = useState<string[]>([]);
  const [jobProgresses, setJobProgresses] = useState<Record<string, any>>({});

  useInterval(
    () => getJobProgresses(jobsToMonitor).then(setJobProgresses),
    jobsToMonitor.length > 0 ? ms('2s') : null
  );

  const monitorJob = useCallback(
    (queueName: keyof typeof config.jobs.queues, jobId: string) => {
      setJobsToMonitor((jobs) => uniq([...jobs, queueName + '|' + jobId]));
    },
    []
  );
  const stopMonitoringJob = useCallback(
    (queueName: keyof typeof config.jobs.queues, jobId: string) => {
      setJobsToMonitor((jobs) =>
        jobs.filter((job) => job !== queueName + '|' + jobId)
      );
    },
    []
  );

  return (
    <JobProgressContext.Provider
      value={{ jobsToMonitor, jobProgresses, monitorJob, stopMonitoringJob }}
    >
      {props.children}
    </JobProgressContext.Provider>
  );
}

export default function useJobProgress<
  QueueName extends keyof typeof config.jobs.queues,
  Progress extends (typeof config.jobs.queues)[QueueName]['types']['progress'],
>(queueName: QueueName, jobId: string | null) {
  const ctx = useContext(JobProgressContext);
  if (!ctx) {
    throw new Error('useJobProgress must be used within a JobProgressProvider');
  }
  const progress = ctx.jobProgresses[queueName + '|' + jobId];

  // useEffect(() => {
  //   if (jobId) {
  //     console.log('monitoring job:', jobId);
  //     ctx.monitorJob(queueName, jobId);
  //     return () => ctx.stopMonitoringJob(queueName, jobId);
  //   }
  // }, [ctx, jobId, queueName]);

  if (!progress) {
    return ['unknown', null, null] as [
      state: 'unknown',
      progress: null,
      finishedOn: null,
    ];
  }

  return progress as [
    state: JobState | 'unknown',
    progress: Progress | number | null,
    finishedOn: number | null,
  ];
}
