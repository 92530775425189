'use client';
import { ActionResult } from '@/lib/action';
import logger from '@/lib/logger';
import {
  createContext,
  useContext,
  forwardRef,
  ForwardedRef,
  useEffect,
} from 'react';
import { useFormState, useFormStatus } from 'react-dom';

export interface FormProps<T>
  extends Omit<React.FormHTMLAttributes<HTMLFormElement>, 'action'> {
  action: (
    prevState: unknown,
    formData: FormData
  ) => Promise<ActionResult<T> | typeof INITIAL>;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  title?: string;
  args?: Record<string, string>;
}

const FormErrorContext = createContext<ActionResult<any>['error']>(null);

export const INITIAL = Symbol.for('initial');

export default forwardRef(function Form<T>(
  {
    action,
    title,
    args,
    onSuccess,
    onError,
    children,
    ...otherProps
  }: FormProps<T>,
  ref: ForwardedRef<HTMLFormElement>
) {
  const [state, formAction] = useFormState(action, INITIAL);
  let error: ActionResult<any>['error'] = null;
  if (state && typeof state === 'object' && 'error' in state) {
    error = state.error;
  }

  return (
    <form {...otherProps} action={formAction} ref={ref} title={title}>
      <FormCallbacks state={state} onSuccess={onSuccess} onError={onError} />
      <FormErrorContext.Provider value={error}>
        <FormError />
        {args &&
          Object.entries(args).map(([key, value]) => (
            <input key={key} type="hidden" name={key} value={value} />
          ))}
        {title && (
          <div className="pb-1 border-b border-contrast-100 font-medium">
            {title}
          </div>
        )}
        {children}
      </FormErrorContext.Provider>
    </form>
  );
});

function FormCallbacks({
  state,
  onSuccess,
  onError,
}: {
  state: ActionResult<unknown>;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  error?: any;
}) {
  const { pending: isCurrentlyPending } = useFormStatus();
  useEffect(() => {
    const isInitial = state === INITIAL;
    const hasResponse = !isCurrentlyPending && !isInitial;
    const isErrorResponse = Boolean(
      state && typeof state === 'object' && 'error' in state && state.error
    );

    if (!hasResponse) return;

    if (!isErrorResponse) {
      onSuccess?.();
    } else {
      logger.error('Form error', (state as any).error);
      onError?.((state as any).error);
    }
  }, [isCurrentlyPending, onError, onSuccess, state]);
  return null;
}

export function useFieldErrors(name?: string) {
  const error = useContext(FormErrorContext);
  if (!name) return null;
  if (!error || !('fields' in error)) return null;
  return error.fields[name];
}

export function useFieldError(name?: string) {
  return useFieldErrors(name)?.message;
}

function FormError() {
  const errorCtx = useContext(FormErrorContext);
  const formError =
    errorCtx &&
    (('form' in errorCtx && ([null, errorCtx.form] as const)) ||
      ('fields' in errorCtx &&
        Object.entries(errorCtx.fields).find(
          ([, error]) => (error as any).message === 'Required'
        )));
  if (!formError) return null;
  const [field, error] = formError;
  return (
    <div className="bg-red-500/50 rounded px-3 py-2 text-sm font-sans overflow-auto whitespace-pre my-2">
      <div className="font-medium">
        Whoops, something's not quite right there:
      </div>
      {field}
      {error.message.trim()}
    </div>
  );
}
