'use client';
import deferred, { Deferred } from '@/lib/deferred';
import {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useState,
} from 'react';
import {
  DialogHeader,
  DialogFooter,
  DialogContent,
  DialogTitle,
  DialogDescription,
  Dialog,
} from './ui/Dialog';
import { Button, ButtonProps } from './ui/Button';
import { SetStateAction } from 'jotai';

type ConfirmOptions = {
  title?: ReactNode;
  description?: ReactNode;
  confirmLabel?: string;
  confirmVariant?: ButtonProps['variant'];
  cancelLabel?: string;
  cancelVariant?: ButtonProps['variant'];
  action?: () => Promise<unknown>;
};

const ConfirmContext = createContext<{
  deferred: [
    Deferred<boolean> | null,
    Dispatch<SetStateAction<Deferred<boolean> | null>>,
  ];
  options: [
    ConfirmOptions | null,
    Dispatch<SetStateAction<ConfirmOptions | null>>,
  ];
} | null>(null);

export function useConfirm<SuppliedOptions extends Partial<ConfirmOptions>>(
  options: SuppliedOptions = {} as SuppliedOptions
) {
  type NeededOptions = Omit<ConfirmOptions, keyof SuppliedOptions>;
  type OverrideOptions = Partial<SuppliedOptions> & NeededOptions;
  const ctx = useContext(ConfirmContext);
  if (!ctx) throw new Error('Used outside provider');
  const [, setConfirmOptions] = ctx.options;
  const [, setConfirmDeferred] = ctx.deferred;

  return function confirm<Overrides extends OverrideOptions>(
    overrides: Overrides = {} as Overrides
  ): Promise<boolean> {
    const confirmDeferred = deferred<boolean>();
    setConfirmDeferred(confirmDeferred);
    setConfirmOptions({ ...options, ...overrides });
    return confirmDeferred;
  };
}

export function Confirm(props: { children: ReactNode }) {
  const options = useState<ConfirmOptions | null>(null);
  const deferred = useState<Deferred<boolean> | null>(null);

  return (
    <ConfirmContext.Provider value={{ options, deferred }}>
      {props.children}
      <ConfirmDialog />
    </ConfirmContext.Provider>
  );
}

function ConfirmDialog() {
  const ctx = useContext(ConfirmContext);
  if (!ctx) throw new Error('Used outside provider');
  const [options, setConfirmOptions] = ctx.options;
  const [deferred, setConfirmDeferred] = ctx.deferred;
  if (!options || !deferred) return null;

  return (
    <Dialog
      open={true}
      onOpenChange={(open) => {
        if (!open) {
          deferred.resolve(false);
          setConfirmOptions(null);
          setConfirmDeferred(null);
        }
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{options.title}</DialogTitle>
          <DialogDescription>{options.description}</DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant={options.cancelVariant ?? 'secondary'}
            onClick={async () => {
              deferred.resolve(false);
              setConfirmOptions(null);
              setConfirmDeferred(null);
            }}
          >
            {options.cancelLabel ?? 'Cancel'}
          </Button>
          <Button
            variant={options.confirmVariant ?? 'destructive'}
            onClick={async () => {
              deferred.resolve(true);
              if (options.action) {
                await options.action();
              }
              setConfirmOptions(null);
              setConfirmDeferred(null);
            }}
          >
            {options.confirmLabel ?? 'Confirm'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function WithConfirm(props: {
  children: (confirm: ReturnType<typeof useConfirm>) => ReactNode;
}) {
  return props.children(useConfirm());
}
