import app from './app';
import mocks from './mocks';

const oauth = {
  intercom: {
    redirectUrl(state: string) {
      if (mocks.intercom.enabled) {
        return `${mocks.publicUrl}/intercom/oauth?state=${state}`;
      }
      return `https://app.intercom.com/oauth?client_id=${oauth.intercom.clientId}&state=${state}`;
    },
    get exchangeUrl() {
      if (mocks.intercom.enabled) {
        return `${mocks.internalUrl}/intercom/oauth/token`;
      }
      return `https://api.intercom.io/auth/eagle/token`;
    },
    clientId: process.env.NEXT_PUBLIC_INTERCOM_CLIENT_ID!,
    clientSecret: process.env.INTERCOM_CLIENT_SECRET!,
    broadcastChannel: 'intercom-oauth-channel',
  },

  zendesk: {
    subdomain: 'TODO',
    redirectUrl(state: string) {
      if (mocks.zendesk.enabled) {
        return `${mocks.publicUrl}/zendesk/oauth?state=${state}`;
      }
      const url = new URL(
        `https://${oauth.zendesk.subdomain}.zendesk.com/oauth/authorizations/new`
      );
      url.searchParams.set('response_type', 'code');
      url.searchParams.set('redirect_uri', oauth.zendesk.callbackUrl);
      url.searchParams.set('client_id', oauth.zendesk.clientId);
      url.searchParams.set('scope', oauth.zendesk.scope);
      url.searchParams.set('state', state);
      return url.toString();
    },
    get exchangeUrl() {
      if (mocks.zendesk.enabled) {
        return `http://mocks:8080/zendesk/oauth/token`;
      }
      return `https://${oauth.zendesk.subdomain}.zendesk.com/oauth/tokens`;
    },
    get callbackUrl() {
      return `${app.url}/oauth/zendesk/callback`;
    },
    scope: 'TODO',
    clientId: process.env.NEXT_PUBLIC_ZENDESK_CLIENT_ID!,
    clientSecret: process.env.ZENDESK_CLIENT_SECRET!,
    broadcastChannel: 'zendesk-oauth-channel',
  },
};

export default oauth;
