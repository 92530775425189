'use client';
import { Confirm } from '@/components/Confirm';
import { GlobalProgressBar } from '@/components/GlobalProgress';
import config from '@/config';
import { JobProgressProvider } from '@/lib/hooks/useJobProgress';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { type ReactNode } from 'react';

if (typeof window !== 'undefined') {
  posthog.init(config.analytics.posthog.key, {
    api_host: config.analytics.posthog.host,
  });
}

export default function Providers(props: { children: ReactNode }) {
  return (
    <>
      <PostHogProvider client={posthog}>
        <GlobalProgressBar>
          <JobProgressProvider>
            <Confirm>{props.children}</Confirm>
          </JobProgressProvider>
        </GlobalProgressBar>
      </PostHogProvider>
    </>
  );
}
