import app from '../app';
import env from '../env';

const capturer = {
  captureQueueUrl: process.env.CAPTURE_QUEUE_URL!,
  dpiFactor: 2,
  assetServer: {
    port: 3009,
  },
  compositor: {
    url: (env.isDevelopment ? app.internalUrl! : app.url) + '/compositor',
  },
};

export default capturer;
