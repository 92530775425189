import app from './app';

const buckets = ['captures', 'cdn', 'candidates', 'assets', 'logos'] as const;
export type Bucket = (typeof buckets)[number];

const storage = {
  credentials: {
    accessKeyId: process.env.TIGRIS_ACCESS_KEY_ID!,
    secretAccessKey: process.env.TIGRIS_SECRET_ACCESS_KEY!,
  },
  buckets,
  fullyQualifiedBucket: (bucket: Bucket) =>
    `${bucket}.storage.${new URL(app.url).hostname}`,
  publicUrl: (bucket: Bucket, key: string) =>
    `https://${storage.fullyQualifiedBucket(bucket)}/${key}`,
};

export default storage;
