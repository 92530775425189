import env from '../env';

const recorder = {
  port: 8080,
  url: process.env.NEXT_PUBLIC_RECORDER_PUBLIC_URL!,
  connectionRetries: env.isProduction ? 3 : 1,
  chromeClip: { x: 10, y: 97 },
  dpiFactor: 1,
};

export default recorder;
