import recorder from "./recorder";
import capturer from "./capturer";
import scraper from "./scraper";

const services = {
  recorder,
  capturer,
  scraper,
};

export default services;
