import { DefaultJobOptions } from 'bullmq';
import ms from 'ms';
import env from './env';

const jobs = {
  connection: process.env.REDIS_URL!,
  queues: {
    scheduler: {
      name: 'scheduler',
      interval: {
        captures: env.isDevelopment ? ms('10s') : ms('1h'),
        scraper: env.isDevelopment ? ms('10s') : ms('1h'),
      },
      types: { data: {}, progress: {} as number },
      defaultJobOptions: {
        removeOnComplete: 100,
        removeOnFail: 100,
        attempts: 3,
        backoff: ms('5m'),
        keepLogs: 500,
      },
    },
    captures: {
      name: 'captures',
      types: {
        data: {} as { assetId: string },
        progress: {} as number,
        result: {} as { captureId: string } | null,
      },
      defaultJobOptions: {
        removeOnComplete: 1000,
        removeOnFail: 5000,
        attempts: 3,
        backoff: ms('1h'),
        keepLogs: 500,
      },
    },
    scraper: {
      name: 'scraper',
      types: {
        data: {} as {
          publishTargetId: string;
        },
        progress: {} as {
          handled: number;
          total: number;
          candidates: { count: number; recent: string[] };
        },
      },
      defaultJobOptions: {
        removeOnComplete: 1000,
        removeOnFail: 5000,
        attempts: 3,
        backoff: ms('1h'),
        keepLogs: 500,
      },
    },
  } satisfies Record<
    string,
    {
      name: string;
      interval?: number | Record<string, number>;
      types: { data: {}; progress: {} | number; result?: {} | null };
      defaultJobOptions: DefaultJobOptions;
    }
  >,
};
export default jobs;
