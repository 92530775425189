"use client";

import ms from "ms";
import { useTheme } from "next-themes";
import { Toaster as Sonner } from "sonner";

type ToasterProps = React.ComponentProps<typeof Sonner>;

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme();

  return (
    <Sonner
      theme={theme as ToasterProps["theme"]}
      className="toaster group"
      toastOptions={{
        duration: ms("10s"),
        unstyled: true,
        classNames: {
          toast:
            "group toast group-[.toaster]:bg-white group-[.toaster]:text-gray-950 border-gray-800/20 shadow-xl border dark:group-[.toaster]:bg-gray-950 dark:group-[.toaster]:text-gray-50 dark:border-gray-800/20 pl-4 py-3 pr-4 text-sm leading-tight rounded-lg row-1",
          title: "shrink",
          description:
            "flex-shrink-1 group-[.toast]:text-gray-500 dark:group-[.toast]:text-gray-400",
          error:
            "group-[.toaster]:bg-red-700 group-[.toaster]:text-white dark:group-[.toaster]:bg-red-600 dark:group-[.toaster]:text-white",
          actionButton:
            "group-[.toast]:bg-gray-900 group-[.toast]:text-gray-50 dark:group-[.toast]:bg-gray-50 dark:group-[.toast]:text-gray-900",
          cancelButton:
            "group-[.toast]:bg-gray-100 group-[.toast]:text-gray-500 dark:group-[.toast]:bg-gray-800 dark:group-[.toast]:text-gray-400",
        },
      }}
      {...props}
    />
  );
};

export { Toaster };
