import config from '@/config';
import chalk from 'chalk';

export type Level = 'debug' | 'info' | 'warn' | 'error' | 'fatal';
enum LogLevels {
  debug,
  info,
  warn,
  error,
  fatal,
}

const levelColors: { [P in Level]: (str: string) => string } = {
  debug: chalk.gray,
  info: chalk.blueBright,
  warn: chalk.yellow.bold,
  error: chalk.red.bold,
  fatal: chalk.bgRed,
};

const prefixColors = [
  chalk.blueBright,
  chalk.cyanBright,
  chalk.greenBright,
  chalk.magentaBright,
  chalk.whiteBright,
  chalk.yellowBright,
];

type WellKnownData = {
  error?: Error | unknown;
};

type LoggerData = WellKnownData & Record<string, unknown>;

const logLevel = LogLevels.info;

let colorIndex = 0;

export class Logger {
  prefixes: string[] = [];
  data: LoggerData | undefined;
  private coloredPrefixes: string[] = [];

  constructor(options: { prefix?: string | string[]; data?: LoggerData } = {}) {
    this.prefixes = this.prefixes.concat(options.prefix ?? []);
    this.coloredPrefixes = this.prefixes.map((p) =>
      prefixColors[colorIndex++ % prefixColors.length]!('[' + p + ']')
    );
    this.data = options.data;
  }

  public log(level: Level, message: string, data?: LoggerData) {
    if (LogLevels[level] < logLevel) {
      return;
    }
    const color = levelColors[level as Level] ?? chalk.reset;
    const formattedLevel = color(level.toUpperCase());

    const args: any[] = [formattedLevel, ...this.coloredPrefixes, message];
    if (arguments.length > 2) {
      args.push(data);
    }

    console.log(...args);
  }

  public debug(...args: [message: string, data?: LoggerData]) {
    this.log('debug', ...args);
  }
  public info(...args: [message: string, data?: LoggerData]) {
    this.log('info', ...args);
  }
  public warn(...args: [message: string, data?: LoggerData]) {
    this.log('warn', ...args);
  }
  public error(...args: [message: string, data?: LoggerData]) {
    this.log('error', ...args);
  }
  public fatal(...args: [message: string, data?: LoggerData]) {
    this.log('fatal', ...args);
  }
  public timer(label: string) {
    let lastTime: number | null = null;
    return (message: string) => {
      const now = Date.now();
      this.log(
        'info',
        `${chalk.yellow(label + ':')} ${message} ${chalk.blue(
          now - (lastTime ?? Date.now()) + 'ms'
        )}`
      );
      lastTime = now;
    };
  }

  public child(
    options: {
      data?: Record<string, unknown>;
      level?: Level;
      prefix?: string;
    } = {}
  ) {
    return new Logger({
      ...options,
      prefix: this.prefixes.concat(options.prefix ?? []),
    });
  }
}

const logger = new Logger();
export default logger;
