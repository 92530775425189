import analytics from './analytics';
import app from './app';
import captures from './captures';
import cdn from './cdn';
import env from './env';
import jobs from './jobs';
import logs from './logs';
import marketing from './marketing';
import metrics from './metrics';
import mocks from './mocks';
import oauth from './oauth';
import publishing from './publishing';
import services from './services';
import sessions from './sessions';
import storage from './storage';

const config = {
  analytics,
  app,
  captures,
  cdn,
  env,
  jobs,
  logs,
  marketing,
  metrics,
  mocks,
  oauth,
  publishing,
  services,
  sessions,
  storage,
};

export default config;
