'use client';
import { Button, ButtonProps } from './Button';
import { useFormStatus } from 'react-dom';
import useResizeObserver from '@react-hook/resize-observer';
import { ReactNode, useRef, useState } from 'react';
import Spinner from './Spinner';

export default function SubmitButton(props: ButtonProps) {
  const { pending } = useFormStatus();

  return (
    <Button variant="primary" {...props} type="submit" disabled={pending}>
      <KeepSize keep={pending}>
        {pending ? <Spinner size={14} /> : props.children}
      </KeepSize>
    </Button>
  );
}

function KeepSize(props: { keep: boolean; children: ReactNode }) {
  const ref = useRef<HTMLDivElement>(null);
  const [{ width, height }, setSize] = useState({ width: 0, height: 0 });
  useResizeObserver(ref.current, (entry) => {
    setSize({
      width: entry.borderBoxSize[0].inlineSize,
      height: entry.borderBoxSize[0].blockSize,
    });
  });

  return (
    <div
      className="row flex-auto overflow-hidden justify-center items-center"
      ref={ref}
      style={props.keep ? { width, height } : {}}
    >
      {props.children}
    </div>
  );
}
