const mocks = {
  port: Number(process.env.PORT ?? 8080),
  publicUrl: process.env.NEXT_PUBLIC_MOCK_SERVER_PUBLIC_URL!,
  internalUrl: process.env.NEXT_PUBLIC_MOCK_SERVER_INTERNAL_URL!,

  storage: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_STORAGE === 'true',
    dir: 'tmp/storage',
  },

  cdn: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_CDN === 'true',
    dir: 'tmp/cdn',
  },

  intercom: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_INTERCOM === 'true',
    sites: {
      tmpdir: 'tmp/intercom-sites',
    },
  },

  zendesk: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_ZENDESK === 'true',
    sites: {
      tmpdir: 'tmp/zendesk-sites',
    },
  },

  website: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_WEBSITE === 'true',
  },

  emails: {
    enabled: process.env.NEXT_PUBLIC_USE_MOCK_EMAILS === 'true',
  },
};
export default mocks;
