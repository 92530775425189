const env = {
  environment: process.env.NEXT_PUBLIC_APP_ENV,
  get isDevelopment() {
    return env.environment === 'development';
  },
  get isTest() {
    return env.environment === 'test';
  },
  get isStaging() {
    return env.environment === 'staging';
  },
  get isProduction() {
    return env.environment === 'production';
  },

  /** Returns true if we are in a browser environment (desktop or web) */
  get isBrowser() {
    return typeof window !== 'undefined';
  },
};

export default env;
