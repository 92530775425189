import { PublishType } from '@prisma/client';

const publishing = {
  /**
   * These publish target types support automatic asset candidate replacement.
   *
   * These targets will automatically replace an asset candidate with it's
   * published Cedar asset when the asset is published.
   *
   * Other target types won't automatically replace it, so the user will need
   * to update the target manually to point to Cedar.
   */
  replacableTargetTypes: [
    PublishType.INTERCOM,
    PublishType.ZENDESK,
  ] as PublishType[],

  displayNames: {
    INTERCOM: 'Intercom',
    ZENDESK: 'Zendesk',
    SALESFORCE: 'Salesforce',
    HELPSCOUT: 'Help',
    HUBSPOT: 'Hubspot',
    ELEVIO: 'Elevio',
    HELPJUICE: 'Helpjuice',
    READMEIO: 'Readme',
    GITBOOK: 'Gitbook',
    WEBSITE: 'website',
  },

  displayNouns: {
    INTERCOM: 'Intercom help center',
    ZENDESK: 'Zendesk help center',
    SALESFORCE: 'Salesforce knowledge base',
    HELPSCOUT: 'Help Scout help center',
    HUBSPOT: 'Hubspot knowledge base',
    ELEVIO: 'Elevio knowledge base',
    HELPJUICE: 'Helpjuice knowledge base',
    READMEIO: 'Readme developer hub',
    GITBOOK: 'Gitbook docs',
    WEBSITE: 'website',
  } satisfies { [P in PublishType]: string },
};

export default publishing;
