import app from "./app";
import ms from "ms";

const sessions = {
  get cookieDomain() {
    return app.url.startsWith("http://localhost")
      ? "localhost"
      : new URL(app.url).hostname;
  },
  get recorderCookieDomain() {
    return app.url.startsWith("http://localhost")
      ? "localhost:8080"
      : "recorder." + new URL(app.url).hostname;
  },
  lifetime: ms("30d"),
};

export default sessions;
