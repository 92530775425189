export type Deferred<T = void> = Promise<T> & {
  resolve: (value: T) => void;
  reject: (reason: unknown) => void;
  resolved: boolean | undefined;
  rejected: boolean | undefined;
};
export default function deferred<T = void>(): Deferred<T> {
  let resolve: (value: T) => void;
  let reject: (reason: unknown) => void;
  const promise: Deferred<T> = new Promise<T>((_resolve, _reject) => {
    resolve = _resolve;
    reject = _reject;
  }) as any;
  promise.resolve = (value) => {
    promise.resolved = true;
    promise.rejected = false;
    resolve(value);
  };
  promise.reject = (value) => {
    promise.resolved = false;
    promise.rejected = true;
    reject(value);
  };
  promise.resolved = false;
  return promise;
}
