import { Loader2 } from 'lucide-react';
import cn from 'mxcn';

export default function Spinner(props: { size?: number; className?: string }) {
  return (
    <Loader2
      size={props.size}
      className={cn(props.className, 'animate-spin opacity-50')}
    />
  );
}
